import { FC } from 'react'

export const InfoIconNotification: FC = () => (
  <>
    <rect x="0" y="0" width="24" height="24" rx="12" fill="#004b81" />
    <circle
      cx="12"
      cy="12"
      r="6"
      stroke="#e5f4fd"
      fill="none"
      strokeWidth="1"
    />
    <path
      d="M12 14.25V12M12 9.75H12.0056"
      stroke="#e5f4fd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default InfoIconNotification
