import { FC, useEffect, useRef } from 'react'
import styles from './Notification.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { useEscapeOnClose } from 'src/ui/hooks/useEscapeOnClose'
import { useTrans } from 'src/ui/hooks/useTrans'
import { NotificationContent } from './NotificationContent'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { PortalNotification } from './PortalNotification'
import { NotificationTypes } from './NotificationTypes.model'

interface Props {
  title: string
  type: NotificationTypes
  isOpen: boolean
  closeAutomaticallyAfter?: number
  onClose?: () => void
  ariaLabel?: string
  'data-testid'?: string
  'data-quantum-warning'?: string | undefined
  description?: string
}

export const Notification: FC<Props> = ({
  title,
  type,
  isOpen,
  ariaLabel,
  closeAutomaticallyAfter,
  onClose,
  'data-testid': testId,
  'data-quantum-warning': quantumWarning,
  description,
}) => {
  const { trans } = useTrans(['common'])
  const timer = useRef<NodeJS.Timeout | null>(null)

  useEscapeOnClose({ onClose })

  useEffect(() => {
    if (
      isUndefined(onClose) ||
      isUndefined(closeAutomaticallyAfter) ||
      isUndefined(isOpen)
    ) {
      return
    }

    timer.current = setTimeout(() => {
      onClose()
    }, closeAutomaticallyAfter)

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [closeAutomaticallyAfter, isOpen, onClose])

  const handleNotificationClose = () => {
    if (isUndefined(onClose)) {
      return
    }

    if (timer.current) {
      clearTimeout(timer.current)
    }

    onClose()
  }

  return (
    <PortalNotification isOpen={isOpen}>
      <div
        className={styles.notificationWrapper}
        aria-label={ariaLabel}
        role="dialog"
        data-testid={testId}
        data-quantum-warning={quantumWarning}
      >
        <div
          className={classNames(styles.notification, styles[type])}
          onClick={e => e.stopPropagation()}
        >
          {onClose && (
            <button
              className={styles.exitButton}
              onClick={handleNotificationClose}
              aria-label={trans('modal_close-button_aria-label')}
            >
              <Icon size="m" color="icon-dark" icon={CloseIcon} />
            </button>
          )}
          <NotificationContent
            title={title}
            description={description}
            type={type}
          />
        </div>
      </div>
    </PortalNotification>
  )
}
